import { Model, Attribute } from './decorators';
import { BaseApiModel } from './base';
import { DocumentTaskType } from '@parashift/shared/types';
import { WorkflowStep } from './log.model';

const DocumentTaskStatus = {
  waiting: $localize `:@@common.waiting:Waiting` as 'waiting',
  pending: $localize `:@@common.pending:Pending` as 'pending',
  in_progress: $localize `:@@common.in_progress:In progress` as 'in_progress',
  done: $localize `:@@common.done:Done` as 'done',
  skipped: $localize `:@@common.skipped:Skipped` as 'skipped',
  failed: $localize `:@@common.failed:Failed` as 'failed'
};
type DocumentTaskStatus = (typeof DocumentTaskStatus)[keyof typeof DocumentTaskStatus];
export { DocumentTaskStatus };

export interface DocumentTaskPlainModel {
  id: string;
  created_at: string;
  document_id: number;
  status: DocumentTaskStatus;
  subject: string;
  updated_at: string;
  workflow_step: WorkflowStep;
  workflow_task: string;
}

@Model({ type: DocumentTaskType })
export class DocumentTask extends BaseApiModel<DocumentTaskPlainModel> {

  @Attribute()
  created_at: string;

  @Attribute()
  document_id: number;

  @Attribute()
  status: DocumentTaskStatus;

  @Attribute()
  subject: string;

  @Attribute()
  updated_at: string;

  @Attribute()
  workflow_step: WorkflowStep;

  @Attribute()
  workflow_task: string;
}
